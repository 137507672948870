<template>
    <div>
        <div class="search_bar">
			<v-select
				:items="cities"
                v-model="filter.from_location"
				placeholder="Откуда"
				prepend-inner-icon="mdi-map-marker"
				height="48"
				hide-details
				outlined
				dense
                :loading="citiesLoading"
                item-text="name"
                item-value="id"
			></v-select>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filter.from_date"
                        placeholder="Дата выезда"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        outlined
                        height="48"
                        readonly
                        dense
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="filter.from_date"
                    :first-day-of-week="1"
                    :allowed-dates="$allowedDates"
                    no-title
                    @input="menu = false"
                ></v-date-picker>
            </v-menu>
			<people-count ref="peopleCount" :isPensioner='true' :model='filter'/>
			<v-btn @click="find" color="primary" height="48" width="160">
				Найти
			</v-btn>
		</div>
		<near-dates v-if="nearDates.length" :nearDates='nearDates' @onClick='nearClick'/>
        <div class="tours_wrapper">
            <v-expansion-panels v-model="panel" >
                <v-expansion-panel
                    v-for="(item, i) in items"
                    :key="item.id"
                    class="mb-5"
                    readonly
                >
                    <v-expansion-panel-header  class="panel_header" hide-actions>
                        <div class="avatar" :style="{backgroundImage: `url(${item.to_location.main_image})`}"></div>
                        <div class="description">
                            <div class="description__text">
                                <div>
                                    <p>{{item.to_location.name}}</p>
                                    <p>{{$moment(item.from_date*1000).format('DD MMMM')}} - {{$moment(item.to_date*1000).format('DD MMMM')}}</p>
                                </div>
                                <p>Номер автобуса: {{item.bus.number}}</p>
                            </div>
                            <div class="description__actions">
                                <div class="price">
                                    <p>{{item.price}} KZT</p>
                                    <p>Цена за всех</p>
                                </div>
                                <div class="buttons">
                                    <v-btn
                                        width="160"
                                        height="44"
                                        outlined
                                        color="#52B1BA"
                                        @click="onClick(item)"
                                    >
                                        Выбрать
                                    </v-btn>
                                    <v-btn
                                        width="160"
                                        height="44"
                                        outlined
                                        color="#FF8413"
                                        @click="onToggle(i, item)"
                                        :loading='item.loading'
                                    >
                                        {{panel==item.id ? 'Скрыть' : 'Подробнее'}}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="panel_content">
                        <div v-if="item.details" class="panel_content__header">
                            <div>
                                <p class="panel_content__header__title">В СТОИМОСТЬ ТУРА ВХОДИТ:</p>
                                <div v-html="item.details.included_in_tour_price" class="orange_circles">
                                </div>
                            </div>
                            <div>
                                <p  class="panel_content__header__title">с собой взять:</p>
                                <div v-html="item.details.take_with_you" class="orange_circles">
                                </div>
                            </div>
                            <div>
                                <p  class="panel_content__header__title">МАРШРУТ:</p>
                                <div v-html="item.details.route" class="routes">
                                </div>
                            </div>
                        </div>
                        <div v-if="item.details" class="panel_content__footer">
                            <div>
                                <p class="panel_content__header__title">УСЛОВИЯ:</p>
                                <div v-html="item.details.conditions" class="orange_circles">
                                </div>
                            </div>
                            <div class="gallery">
                                <div v-for="i in item.details.to_location.tour_images" :key="i.id" class="avatar" :style="{backgroundImage: `url(${i.image})`}"></div>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
import peopleCount from '@/components/ui/peopleCount.vue'
import NearDates from '@/components/ui/nearDates.vue'
import { toursService } from '@/services/tours.js'
export default {
    components: {
        peopleCount,
        NearDates
    },
    data: () => ({
        filter: {
            adult: 1,
            child: 0,
            pensioner: 0,
            from_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
        citiesLoading: false,
        img: require('@/assets/images/test/test2.png'),
        menu: false,
        panel: null,
        nearDates: [],
        items: [],
        cities: []
    }),
    watch: {
        '$route.name'(){
            console.log(2);
        }
    },
    async created() {
        await this.getCities()
        if (Object.keys(this.$route.query).length) {
            this.filter.adult = Number(this.$route.query.adult)
            this.filter.child = Number(this.$route.query.child)
            this.filter.pensioner = Number(this.$route.query.pensioner)
            this.filter.from_date = this.$route.query.from_date
            this.filter.from_location = Number(this.$route.query.from_location)
            this.find()
        }
    }, 
    methods: {
        isValidSearch() {
            let is_valid = false
            is_valid = (Number(this.filter.adult)+Number(this.filter.child)+Number(this.filter.pensioner)) > 0 && this.filter.from_location && this.filter.from_date
            if (!is_valid) {
                let message = 'Выберите все поля'
                this.$store.commit('ui/SET_NOTIFICATION', {show: true, message, color: 'error'})
            }
            return is_valid
        },
        onClick(item) {
            this.$router.push({
                path: `/booking/tours/${item.id}/`,
                query: this.filter
            })
        },
        nearClick(date) {
            this.filter.from_date = this.$copy(this.$moment(Number(date.near_date)*1000).format('YYYY-MM-DD'))
            this.find()
        },
        async onToggle(idx, item) {
            item.loading = true
            item.details = await toursService.getSingleActiveTours({id: item.id})
            item.loading = false
            if (this.panel === idx) {
                this.panel = null
                return
            }
            this.panel = idx
        },
        async find() {
            if (!this.isValidSearch()) return
            await this.getTours()
        },
        async getTours() {
            try {
                this.$router.push({ name: this.$route.name, query: this.filter })
                this.$loading(true)
                const { results } = await toursService.getActiveTours(this.filter)
                const { adult, child, pensioner, from_date } = this.filter
                this.nearDates = (await toursService.getNearDates({adult, child, pensioner, from_date})).results
                this.items = results
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        async getCities() {
            try {
                this.citiesLoading = true
                const { results } = await toursService.getTourLocations()
                this.cities = results
            } catch(e) {
                console.error(e);
            } finally {
                this.citiesLoading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.condition{
    background-color: whitesmoke;
    padding: 11px;
    padding-bottom: 1px;
    margin: 7px 0 !important;
    border-radius: 5px;
}
.search_bar {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 160px;
	align-items: center;
}
.tours_wrapper {
    padding-top: 20px;
}
.avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.panel_header {
    // height: 149px;
    padding: 20px 40px 20px 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 191px 1fr;
    grid-column-gap: 14px;
    align-items: start;
    cursor: default;
    .description {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 0px;
        height: 100%;
        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: 100%;
            p {
                &:last-of-type {
                    font-size: 12px;
                    line-height: 14px;
                    color: #021011;
                    margin-bottom: 0;
                }
            }
            div {
                p {
                    &:nth-of-type(1) {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                        margin-bottom: 6px;
                    }
                    &:nth-of-type(2) {
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                        margin-bottom: 0;
                    }
                    
                }
            }
            
        }
        &__actions {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            height: 100%;
            .price {
                text-align: center;
                margin-top: 20px;
                p {
                    &:first-of-type {
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        color: #021011;
                        margin-bottom: 6px;
                    }
                    &:last-of-type {
                        font-size: 12px;
                        line-height: 14px;
                        color: #000000;
                        margin-bottom: 0;
                    }
                }
            }
            .buttons {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                height: 100%;
                margin-left: 24px;
                button{
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.routes {
    position: relative;
    transform: translateX(12px);
    ::v-deep p {
        position: relative;
    }
    ::v-deep p::before {
        content: url('../../../assets/images/ui/arrow_down.svg');
        position: absolute;
        top: 3px;
        left: -14px;
    }
}
.orange_circles {
    transform: translateX(12px);
    ::v-deep p {
        position: relative;
    }
    ::v-deep p::after {
        content: '';
        position: absolute;
        top: 6px;
        left: -12px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #FF8413;
    }
}
.panel_content {
    p {
        margin-bottom: 2px;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
    &__header {
        padding: 20px 40px 0px 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: start;
        grid-column-gap: 20px;
        justify-content: space-between;
        &__title {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            text-transform: uppercase;
        }
        .routes {
            p {
                margin-bottom: 10px;
            }
        }
    }
    &__footer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 40px 20px 20px;
        .gallery {
            display: grid;
            grid-template-columns: repeat(auto-fit, 191px);
            grid-gap: 20px;
            min-width: 402px;
            div {
                height: 109px;
            }
        }
    }
}

</style>